import { gql } from 'apollo-angular';

export const STRUCTURE_FLAGS = gql`
  query ListStructureFlags {
    structureflags {
      value {
        description
        name
        structure_flag_id
      }
    }
  }
`;

export const TENANT_SELECTOR = gql`
  query ListTenantsForSelector {
    tenants {
      code
      ok
      reason
      value {
        name
        tenant_id
      }
    }
  }
`;

export const LIST_TENANT = gql`
  query ListTenants($skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    tenants(skip: $skip, limit: $limit, sortField: $sortField, sortOrder: $sortOrder) {
      code
      metadata {
        count
        limit
        offset
        requested_at
        type
      }
      ok
      reason
      value {
        active
        address {
          area
          city
          complement
          country
          latitude
          longitude
          number
          state
          street
          zip
        }
        affiliated_id
        cpf_cnpj
        email
        financial_contract
        legal_name
        name
        phone
        structure_flags
        structure_id
        structure_item_id
        tenant_id
        website
        whatsapp
      }
    }
  }
`;

export const STRUCTURES = gql`
  query ListStructures($tenantId: String!) {
    structures(tenantId: $tenantId) {
      value {
        flags
        level
        linked_id
        name
        structure_id
        editable
      }
    }
  }
`;

export const STRUCTURES_ADD_STRUCTURE_ITEM = gql`
  query ListLinkedStructures($linkedId: String!) {
    structures(linkedId: $linkedId) {
      value {
        name
        structure_id
      }
    }
  }
`;

export const STRUCTURE_BY_ID = gql`
  query ListStructures($structureId: String!) {
    structures(structureId: $structureId) {
      value {
        name
        structure_id
      }
    }
  }
`;

export const STRUCTURE_TEMPLATES = gql`
  query ListStructureTemplates {
    structuretemplates {
      value {
        type
        name
      }
    }
  }
`;

export const LIST_STRUCTURE_ITEMS_FILTER = gql`
  query StructureItemsFilter($affiliatedId: String) {
    list_structure_items_filter(affiliatedId: $affiliatedId) {
      value {
        name
        structure_name
        structure_item_id
        tenant_id
        structure_id
        users_limit
        address {
          street
          number
          complement
          area
          city
          state
          country
          zip
          latitude
          longitude
        }
      }
    }
  }
`;

export const LIST_SINGLE_STRUCTURE_ITEM_FOR_FILTER = gql`
  query ListSingleStructureItemForFilter($structureItemId: String) {
    list_structure_items_filter(structureItemId: $structureItemId) {
      value {
        name
        structure_name
        structure_item_id
        tenant_id
        structure_id
        users_limit
        address {
          street
          complement
          number
          area
          city
          state
          country
          zip
          latitude
          longitude
        }
      }
    }
  }
`;

export const LIST_WHITELABEL = gql`
  query ListWhitelabel($structureItemId: String!) {
    get_whitelabel(structureItemId: $structureItemId) {
      code
      reason
      value {
        domain
        name
        structure_item_id
      }
    }
  }
`;

export const LIST_WHITELABEL_CUSTOMIZATION = gql`
  query ListWhitelabel($structureItemId: String!) {
    get_whitelabel(structureItemId: $structureItemId) {
      code
      reason
      value {
        accent_color
        favicon
        logo_positive
        logo_negative
        structure_item_id
      }
    }
  }
`;

export const LIST_WHITELABEL_APP_INITIALIZER = gql`
  query ListWhitelabel {
    get_whitelabel_by_domain {
      value {
        tenant_id
        structure_item_id
        accent_color
        name
        favicon
        logo_positive
        logo_negative
      }
    }
  }
`;

export const LIST_STRUCTURE_ITEMS_TABLE = gql`
  query ListStructureItemsTable(
    $affiliatedId: String
    $skip: Int
    $limit: Int
    $sortField: String
    $sortOrder: Int
    $query: String
  ) {
    list_structure_items(
      affiliatedId: $affiliatedId
      skip: $skip
      limit: $limit
      sortField: $sortField
      sortOrder: $sortOrder
      query: $query
    ) {
      value {
        tenant_id
        structure_item_id
        structure_id
        structure_name
        name
        cpf_cnpj
        email
        phone
        active
        structure_flags
        users_limit
      }
      metadata {
        count
        limit
        offset
      }
    }
  }
`;

export const LIST_STRUCTURE_ITEMS_ALL_DATA = gql`
  query ListStructureItemsAllData($structureItemId: String) {
    list_structure_items(structureItemId: $structureItemId) {
      value {
        structure_item_id
        tenant_id
        structure_id
        structure_name
        affiliated_id
        name
        cpf_cnpj
        legal_name
        email
        whatsapp
        phone
        website
        active
        users_limit
        address {
          street
          number
          complement
          area
          city
          state
          country
          zip
          latitude
          longitude
        }
        financial_contract
        structure_flags
      }
      metadata {
        count
        limit
        offset
      }
    }
  }
`;

export const LIST_CONTRACTS = gql`
  query ListContracts($structureItemId: String, $skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    contracts(
      structureItemId: $structureItemId
      skip: $skip
      limit: $limit
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      metadata {
        count
        offset
        limit
      }
      value {
        address {
          street
          number
          complement
          area
          city
          state
          country
          zip
          latitude
          longitude
        }
        cameras_limit
        simultaneous_sessions_limit
        recording
        recording_hours
        active
        name
        structure_item_id
        contract_id
      }
    }
  }
`;

export const LIST_LINKED_STRUCTURE_ITEMS = gql`
  query ListLinkedStructureItems($structureItemIds: [String!], $skip: Int, $limit: Int) {
    list_structure_items(structureItemIds: $structureItemIds, skip: $skip, limit: $limit) {
      value {
        name
        structure_item_id
      }
    }
  }
`;

export const LIST_LINKABLE_STRUCTURE_ITEMS = gql`
  query ListLinkedStructureItems($affiliatedId: String, $skip: Int, $limit: Int) {
    list_structure_items(affiliatedId: $affiliatedId, skip: $skip, limit: $limit) {
      value {
        name
        structure_item_id
      }
    }
  }
`;

export const LIST_CONTRACT_TEMPLATES = gql`
  query ListContractTemplates($structureItemId: String, $skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    contract_templates(
      structureItemId: $structureItemId
      skip: $skip
      limit: $limit
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      value {
        contract_template_id
        structure_item_id
        name
        cameras_limit
        simultaneous_sessions_limit
        recording
        recording_hours
        is_default
      }
      metadata {
        count
        offset
        limit
      }
    }
  }
`;

export const LIST_CONTRACT_TEMPLATES_FOR_SELECTOR = gql`
  query ListContractTemplates($structureItemId: String, $skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    contract_templates(structureItemId: $structureItemId) {
      value {
        contract_template_id
        name
        cameras_limit
        simultaneous_sessions_limit
        recording
        recording_hours
      }
      metadata {
        count
        offset
        limit
      }
    }
  }
`;

export const LIST_PROFILES = gql`
  query ListContractTemplates($structureItemId: String, $skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    contract_templates(
      structureItemId: $structureItemId
      skip: $skip
      limit: $limit
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      value {
        contract_template_id
        structure_item_id
        name
        cameras_limit
        simultaneous_sessions_limit
        recording
        recording_hours
        is_default
      }
      metadata {
        count
        offset
        limit
      }
    }
  }
`;
