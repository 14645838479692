import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../environment';

const uriOrganizations = `${environment.api}/organizations/graphql`;
const uriImages = `${environment.api}/images/graphql`;
const uriAuth = `${environment.api}/auth/graphql`;
const version = '2.0.0';
const cache = new InMemoryCache({ addTypename: false });
const headers = {
  'Access-Control-Allow-Origin': '*',
  'apollographql-client-name': 'dgc-web',
  'apollographql-client-version': '2.0.0'
};

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory(httpLink: HttpLink): NamedOptions {
        return {
          default: {
            cache,
            version,
            headers,
            link: httpLink.create({ uri: uriOrganizations }),
            name: 'organizations'
          },
          images: {
            cache,
            version,
            headers,
            link: httpLink.create({ uri: uriImages }),
            name: 'images'
          },
          auth: {
            cache,
            version,
            headers,
            link: httpLink.create({ uri: uriAuth }),
            name: 'auth'
          }
        };
      },
      deps: [HttpLink]
    }
  ]
})
export class GraphQLModule {}
