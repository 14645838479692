import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService: AuthenticationService = inject(AuthenticationService);
  const isLoggedIn = authService.isLogged();
  const isTryingToAccessChangeProfile = state.url.includes('change-profile');
  const isTryingToAccessTerms = state.url.includes('terms');
  const isTryingToAccessAuthRoutes =
    state.url.startsWith('/authentication') && !isTryingToAccessTerms && !isTryingToAccessChangeProfile;
  const router = inject(Router);

  if (isLoggedIn && isTryingToAccessAuthRoutes) {
    router.navigate(['dashboard/home']);
    return false;
  }

  if (!isLoggedIn && isTryingToAccessAuthRoutes) {
    return true;
  }

  if (isLoggedIn) {
    return true;
  }
  router.navigate(['authentication/login']);
  return false;
};

export const LoggedGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => canActivate(route, state);
