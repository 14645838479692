import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from './cookie.service';
import { Router } from '@angular/router';
import {
  ListProfilesGQLResponse,
  LoadProfileOutputDTO,
  LoginInputDTO,
  LoginOutputDTO,
  OutputDTO,
  StructureItemConfigsDTO
} from '@dgc/dtos';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { PermissionService } from './permission.service';
import { LoginOutput } from '../models/login-output';
import { NbMenuItem } from '@nebular/theme';
import { SocketService } from './socket.service';
import { v4 as getUniqueId } from 'uuid';
import { QuickMenu } from '../models/quick-menu';
import { environment } from '../../environment';
import { StructureItemService } from './structure-item.service';
import { StructureItemConfigsResponse } from '../models/structure-item-configs';
import { DgcCookies } from '../enums/cookies.enum';
import { ListProfilesByUserInput } from '../models/list-profiles-by-user-input';
import { Apollo } from 'apollo-angular';
import { LIST_PROFILES_BY_USER } from '../graphql/auth.queries';
import { ApolloQueryResult } from '@apollo/client';
import { IPermissions } from '../enums/permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private api = `${environment.api}/auth/v1`;
  expirationDays = 1;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private router: Router,
    private permissionService: PermissionService,
    private socketService: SocketService,
    private structureItemService: StructureItemService,
    private apollo: Apollo
  ) {}

  signIn(data: { email: string; password: string; tenant_id: string }): Observable<LoginOutput> {
    const url = `${this.api}/login`;
    const { email, password, tenant_id } = data;
    const loginInput: LoginInputDTO = {
      email,
      password,
      client_id: environment.clientId,
      tenant_id
    };

    return this.http.post<LoginOutput>(url, loginInput).pipe(
      tap((res) => this.handleUser(res.value)),
      // switchMap((res) => this.structureItemService.getStructureItemConfigs(res.value.structure_item_id)), // TODO: Resolver esta questão
      // tap((res) => this.handleStructureItemConfigs(res.value)), // TODO: Resolver esta questão
      catchError((err) => {
        return throwError(() => err);
      })
    );
  }

  getProfilesByUser(
    data: ListProfilesByUserInput
  ): Observable<ApolloQueryResult<{ list_profiles_by_user: ListProfilesGQLResponse }>> {
    return this.apollo.use('auth').query<{ list_profiles_by_user: ListProfilesGQLResponse }>({
      query: LIST_PROFILES_BY_USER,
      variables: {
        userId: data.userId,
        linkedWhitelabel: data.linkedWhitelabel
      },
      fetchPolicy: 'no-cache'
    });
  }

  loadProfile(profileId: string): Observable<OutputDTO<LoadProfileOutputDTO>> {
    const url = `${this.api}/login/load-profile/${profileId}`;
    return this.http
      .get<OutputDTO<LoadProfileOutputDTO>>(url)
      .pipe(tap((profile) => this.handleSelectedProfile(profile.value)));
  }

  signOut(): void {
    this.deleteCookies();
    this.router.navigate(['/authentication/login']);
    // this.socketService.disconnect(); // TODO: lembrar de descomentar
  }

  deleteCookies(): void {
    this.cookieService.deleteCookie(DgcCookies.MENU);
    this.cookieService.deleteCookie(DgcCookies.QUICK_MENU);
    this.cookieService.deleteCookie(DgcCookies.USER);
    this.cookieService.deleteCookie(DgcCookies.PERMISSIONS);
    this.cookieService.deleteCookie(DgcCookies.SESSION_ID);
    this.cookieService.deleteCookie(DgcCookies.STRUCTURE_ITEM);
  }

  isLogged(): boolean {
    return !!this.cookieService.getCookie(DgcCookies.PERMISSIONS);
  }

  getCurrentUser(): LoginOutputDTO {
    const user: LoginOutputDTO = this.cookieService.getCookie(DgcCookies.USER);
    return user;
  }

  getCurrentUserStructureItemId(): string {
    const userStructureItemId = this.cookieService.getCookie(DgcCookies.STRUCTURE_ITEM_ID);
    return userStructureItemId;
  }

  getTenantId(): string {
    const tenantId = this.cookieService.getCookie(DgcCookies.TENANT_ID);
    return tenantId;
  }

  private handleUser(user: LoginOutputDTO): void {
    const sessionId = this.generateSessionId();
    this.saveUserDataToCookies({ user, sessionId });
  }

  handleSelectedProfile(profile: LoadProfileOutputDTO | null): void {
    if (!profile) return;
    const menuItems = this.permissionService.createMenu(profile.permissions);
    const quickMenu = this.permissionService.createQuickMenu(menuItems);
    const { permissions } = profile;
    this.saveUserDataToCookies({
      menuItems,
      quickMenu,
      permissions
    });
    this.permissionService.setMenu(menuItems);
    this.permissionService.setQuickMenu(quickMenu);
  }

  private handleStructureItemConfigs(structureItemConfig: StructureItemConfigsDTO): void {
    this.cookieService.setCookie(DgcCookies.STRUCTURE_ITEM, structureItemConfig, environment.expirationDays);
    // this.socketService.connect(this.user, structureItemConfig); // TODO: lembrar de descomentar
  }

  public saveUserDataToCookies(data: {
    user?: LoginOutputDTO;
    menuItems?: NbMenuItem[];
    permissions?: string[];
    quickMenu?: QuickMenu[];
    sessionId?: string;
  }): void {
    if (data.user) this.cookieService.setCookie(DgcCookies.USER, data.user, environment.expirationDays);
    if (data.menuItems) this.cookieService.setCookie(DgcCookies.MENU, data.menuItems, environment.expirationDays);
    if (data.permissions)
      this.cookieService.setCookie(DgcCookies.PERMISSIONS, data.permissions, environment.expirationDays);
    if (data.quickMenu) this.cookieService.setCookie(DgcCookies.QUICK_MENU, data.quickMenu, environment.expirationDays);
    if (data.sessionId) this.cookieService.setCookie(DgcCookies.SESSION_ID, data.sessionId, environment.expirationDays);
  }

  generateSessionId(): string {
    return getUniqueId();
  }
}
