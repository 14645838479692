import { Inject, Injectable } from '@angular/core';
import { LanguageService } from './language.service';
import { IToastInputModel, IToastService, IToastType } from '../classes/itoast-service.class';
import { TranslationParams } from '../models/translation-params';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  constructor(@Inject('ToastService') private toastService: IToastService, private languageService: LanguageService) {}

  success(data: any): void {
    this.toastService.success(data);
  }

  danger(data: any): void {
    this.toastService.danger(data);
  }

  info(data: any): void {
    this.toastService.info(data);
  }

  warning(data: any): void {
    this.toastService.warning(data);
  }

  translateToast(
    data: { toastInput: IToastInputModel; type: IToastType },
    translate = true,
    translationParams?: TranslationParams[]
  ): void {
    data.toastInput.title = translate
      ? this.languageService.getTranslation(data.toastInput.title)
      : data.toastInput.title;
    data.toastInput.content = translate
      ? this.languageService.getTranslation(data.toastInput.content, translationParams)
      : data.toastInput.content;
    const toastMethod = this[data.type].bind(this);
    toastMethod(data.toastInput);
  }
}
