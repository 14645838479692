export enum IPermissions {
  ADD_TENANT = 'addTenant',
  LIST_TENANTS = 'listTenants',
  EDIT_TENANT = 'editTenant',
  DELETE_TENANT = 'deleteTenant',
  ADD_STRUCTURES = 'addStructures',
  ADD_SERVER = 'addServer',
  LIST_SERVERS = 'listServers',
  EDIT_SERVER = 'editServer',
  DELETE_SERVER = 'deleteServer',
  LIST_ITEMS_LINKED_TO_SERVER = 'listItemslinkedToServer',
  LINK_ITEM_TO_SERVER = 'linkItemToServer',
  UNLINK_ITEM_FROM_SERVER = 'unlinkItemToServer',
  ADD_CAMERA = 'addCamera',
  LIST_CAMERAS = 'listCameras',
  EDIT_CAMERA = 'editCamera',
  DELETE_CAMERA = 'deleteCamera',
  CONFIGURE_WHITELABEL = 'configureWhitelabel',
  LIST_STRUCTURE_ITEMS = 'listStructureItems',
  ADD_STRUCTURE_ITEM = 'addStructureItem',
  EDIT_STRUCTURE_ITEM = 'editStructureItem',
  DELETE_STRUCTURE_ITEM = 'deleteStructureItem',
  LIST_CONTRACTS = 'listContracts',
  ADD_CONTRACT = 'addContract',
  EDIT_BASIC_CONTRACT_INFO = 'editBasicContractInfo',
  EDIT_CONTRACT_RECORDING_PARAMS = 'editContractRecordingParams',
  DELETE_CONTRACT = 'deleteContract',
  TOGGLE_CONTRACTS = 'toggleContract',
  LIST_CONTRACT_TEMPLATES = 'listContractTemplates',
  ADD_CONTRACT_TEMPLATE = 'addContractTemplate',
  EDIT_CONTRACT_TEMPLATE = 'editContractTemplate',
  DELETE_CONTRACT_TEMPLATE = 'deleteContractTemplate',
  TOGGLE_CONTRACT_TEMPLATES = 'toggleDefaultContractTemplate',
  ADD_PROFILE = 'addProfile',
  LIST_PROFILES = 'listProfiles',
  EDIT_PROFILE = 'editProfile',
  DELETE_PROFILE = 'deleteProfile',
  ADD_USER = 'addUser',
  LIST_USERS = 'listUsers',
  EDIT_USER_PROFILES = 'editUserProfiles',
  DELETE_USER = 'deleteUser',
  SET_STRUCTURE_ITEM_USERS_LIMIT = 'setStructureItemUsersLimit'
}

export enum ScreenPermissions {
  VIEW_IMPLANTATION = 'viewImplantation',
  VIEW_HOME = 'viewHome'
}
