import { gql } from 'apollo-angular';

export const LIST_PERMISSIONS_BY_STRUCTURE = gql`
  query ListPermissionsByStructure($structureId: String!) {
    permissions_by_structure(structureId: $structureId) {
      value {
        name
        permissions {
          permission_id
          name
          description
          linked_permissions
        }
      }
    }
  }
`;

export const LIST_PROFILES_TABLE = gql`
  query ListProfilesTable($structureItemId: String!) {
    list_profiles(structureItemId: $structureItemId) {
      metadata {
        count
        offset
        limit
      }
      value {
        profile_id
        name
        description
        total_users
      }
    }
  }
`;

export const LIST_USERS_BY_PROFILE_INFO = gql`
  query ListUsersByProfileInfo($profileId: String!) {
    users_by_profile(profileId: $profileId) {
      value {
        name
      }
    }
  }
`;

export const LIST_PERMISSIONS_BY_PROFILE_INFO = gql`
  query ListPermissionsByProfileInfo($profileId: String!) {
    permissions_by_profile(profileId: $profileId) {
      value {
        name
        permissions {
          name
        }
      }
    }
  }
`;

export const LIST_PERMISSIONS_BY_PROFILE_EDITING = gql`
  query ListPermissionsByProfileEditing($profileId: String!) {
    permissions_by_profile(profileId: $profileId) {
      value {
        permissions {
          permission_id
        }
        name
      }
    }
  }
`;

export const VALIDATE_PROFILE_DELETION = gql`
  query ValidateProfileDeletion($profileId: String!) {
    validate_profile_deletion(profileId: $profileId) {
      value {
        users_affected {
          name
          user_id
        }
        profiles_available {
          profile_id
          name
        }
      }
    }
  }
`;

export const LIST_USERS_TABLE = gql`
  query ListUsersTable($structureItemId: String!, $skip: Int, $limit: Int, $sortField: String, $sortOrder: Int) {
    list_users(
      structureItemId: $structureItemId
      skip: $skip
      limit: $limit
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      value {
        user_id
        tenant_id
        profiles {
          name
          profile_id
        }
        name
        email
        mobile_phone
        is_verified
      }
      metadata {
        type
        count
        offset
        limit
        requested_at
      }
    }
  }
`;

export const LIST_PROFILES_FOR_INVITE = gql`
  query ListProfilesForInvite($structureItemId: String!) {
    list_profiles_for_invite(structureItemId: $structureItemId) {
      value {
        profile_id
        name
      }
    }
  }
`;

export const LIST_PROFILES_BY_USER = gql`
  query ListProfilesByUser($userId: String!, $linkedWhitelabel: String!) {
    list_profiles_by_user(userId: $userId, linkedWhitelabel: $linkedWhitelabel) {
      value {
        profile_id
        name
        structure_item_name
        description
        total_users
      }
    }
  }
`;
