import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, of, switchMap, take, tap } from 'rxjs';
import { PermissionService } from './permission.service';
import { NbMenuItem } from '@nebular/theme';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environment';
import { QuickMenu } from '../models/quick-menu';
import { LanguageService } from './language.service';
import { TranslateService } from '@ngx-translate/core';
import { DgcSessionStorage } from '../enums/session-storage.enum';
import { WhitelabelService } from './whitelabel.service';

export function appInitializerFunc(app: AppInitializerService): () => Observable<any> {
  return () => app.initialize().pipe(catchError((err) => app.handleError(err)));
}

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(
    private permissionService: PermissionService,
    private authService: AuthenticationService,
    private http: HttpClient,
    private languageService: LanguageService,
    private translate: TranslateService,
    private whitelabelService: WhitelabelService
  ) {}

  initialize(): Observable<any> {
    const language = this.languageService.getDefaultLanguage();
    this.translate.setDefaultLang(language);
    return this.translate.use(language).pipe(
      tap(() => {
        if (this.authService.isLogged()) this.storeMenus();
      }),
      switchMap(() => this.whitelabelService.getWhitelabelAppInitializer()),
      switchMap(() => this.checkIfIsDevMode()),
      catchError((err) => this.handleError(err)),
      take(1)
    );
  }

  storeMenus(): void {
    const menuItems: NbMenuItem[] = this.permissionService.getMenu();
    const quickMenuItems: QuickMenu[] = this.permissionService.getQuickMenu();
    this.permissionService.setMenu(menuItems);
    this.permissionService.setQuickMenu(quickMenuItems);
  }

  checkIfIsDevMode(): Observable<boolean | any[]> {
    return environment.env_mode === 'dev' ? this.getHealthCheckCalls() : of(true).pipe(take(1));
  }

  getHealthCheckCalls(): Observable<boolean | any[]> {
    const authHealth$ = this.http.get(`${environment.api}/auth/health`).pipe(take(1));
    const organizationsHealth$ = this.http.get(`${environment.api}/organizations/health`).pipe(take(1));
    const sessionsHealth$ = this.http.get(`${environment.api}/sessions/health`).pipe(take(1));
    const imagesHealth$ = this.http.get(`${environment.api}/images/health`).pipe(take(1));
    const notificationsHealth$ = this.http.get(`${environment.api}/notifications/health`).pipe(take(1));
    const calls$: Observable<any>[] = [
      authHealth$,
      organizationsHealth$,
      sessionsHealth$,
      imagesHealth$,
      notificationsHealth$
    ];
    return forkJoin(calls$).pipe(catchError((err) => this.handleError(err)));
  }

  handleError(err: any): Observable<true> {
    sessionStorage.setItem(DgcSessionStorage.APP_INITIALIZER_ERROR, JSON.stringify(err));
    return of(true);
  }
}
