export enum DgcCookies {
  STRUCTURE_ITEM = 'dgc-structure-item',
  USER = 'dgc-user',
  MENU = 'dgc-menu',
  PERMISSIONS = 'dgc-permissions',
  QUICK_MENU = 'dgc-quick-menu',
  SESSION_ID = 'dgc-session-id',
  TENANT_ID = 'dgc-tenant-id',
  STRUCTURE_ITEM_ID = 'dgc-structure-item-id'
}
