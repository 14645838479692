import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';
import { SocketUserInfo } from '../models/socket-user-info';
import { LoginOutputDTO, StructureItemConfigsDTO } from '@dgc/dtos';
import GetUserInfo from '../libs/get-user-info';
import { CookieService } from './cookie.service';
import { environment } from '../../environment';
import { DgcCookies } from '../enums/cookies.enum';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket: Socket;

  constructor(private cookieService: CookieService) {}

  connect(user: LoginOutputDTO, structureItemConfig: StructureItemConfigsDTO) {
    const socketUserInfo: SocketUserInfo = this.getUserInfoForSocket(user, structureItemConfig);
    const sessionId: string = this.cookieService.getCookie(DgcCookies.SESSION_ID);
    const { accessFrom, email, info, name, structureItemId, structureItemName, tenantId, userid } = socketUserInfo;
    this.socket = new Socket({
      url: environment.socketApi,
      options: {
        extraHeaders: {
          accessFrom,
          email,
          info,
          name,
          structureItemId,
          structureItemName,
          tenantId,
          userid,
          sessionId
        }
      }
    });
  }

  private getUserInfoForSocket(user: LoginOutputDTO, structureItemConfig: StructureItemConfigsDTO): SocketUserInfo {
    const info = GetUserInfo.execute();
    const socketUserInfo: SocketUserInfo = {
      tenantId: '',
      accessFrom: 'web',
      info,
      email: user.email,
      name: user.name,
      structureItemId: '',
      structureItemName: structureItemConfig.name,
      userid: ''
    };
    return socketUserInfo;
  }

  disconnect(): void {
    this.socket.disconnect();
  }
}
