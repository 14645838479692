import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CookieService } from './cookie.service';
import { IPermissions, ScreenPermissions } from '../enums/permissions.enum';
import { QuickMenu } from '../models/quick-menu';
import { TranslateService } from '@ngx-translate/core';
import { DgcCookies } from '../enums/cookies.enum';
import { ApolloQueryResult } from '@apollo/client';
import { ListPermissionsGQLResponse } from '@dgc/dtos';
import { Apollo } from 'apollo-angular';
import { LIST_PERMISSIONS_BY_STRUCTURE } from '../graphql/auth.queries';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private menu$: BehaviorSubject<NbMenuItem[]> = new BehaviorSubject<NbMenuItem[]>([]);
  private quickMenu$: BehaviorSubject<QuickMenu[]> = new BehaviorSubject<QuickMenu[]>([]);
  private items: NbMenuItem[] = [
    {
      title: 'DASHBOARD',
      icon: 'browser-outline',
      children: [
        {
          title: 'HOME',
          link: '/dashboard/home',
          data: ScreenPermissions.VIEW_HOME
        }
      ]
    },
    {
      title: 'RESOURCE',
      icon: 'layers-outline',
      children: [
        {
          title: 'TENANTS',
          link: '/resource/tenants',
          data: IPermissions.LIST_TENANTS
        },
        {
          title: 'CAMERAS',
          link: '/resource/cameras',
          data: IPermissions.LIST_CAMERAS
        }
      ]
    },
    {
      title: 'CONFIGURATION',
      icon: 'settings-outline',
      children: [
        {
          title: 'HIERARCHY',
          link: '/config/structures',
          data: IPermissions.ADD_STRUCTURES
        },
        {
          title: 'SERVERS',
          link: '/config/servers',
          data: IPermissions.LIST_SERVERS
        },
        {
          title: 'IMPLANTATION',
          link: '/config/implantation',
          data: ScreenPermissions.VIEW_IMPLANTATION
        },
        {
          title: 'GENERAL_PARAMS',
          link: '/config/general-params',
          data: 'viewGeneralParams'
        },
        {
          title: 'SYSTEM',
          link: '/config/system',
          data: IPermissions.CONFIGURE_WHITELABEL
        },
        {
          title: 'CUSTOMIZATION',
          link: '/config/customization',
          data: IPermissions.CONFIGURE_WHITELABEL
        },
        {
          title: 'CONTRACT_TEMPLATES',
          link: '/config/contract-templates',
          data: IPermissions.LIST_CONTRACT_TEMPLATES
        },
        {
          title: 'PROFILES',
          link: '/config/profiles',
          data: IPermissions.LIST_PROFILES
        },
        {
          title: 'USERS',
          link: '/config/users',
          data: IPermissions.LIST_USERS
        }
      ]
    }
  ];

  constructor(private cookieService: CookieService, private translate: TranslateService, private apollo: Apollo) {}

  getMenuItems(): Observable<NbMenuItem[]> {
    return this.menu$.asObservable();
  }

  getQuickMenuItems(): Observable<QuickMenu[]> {
    return this.quickMenu$.asObservable();
  }

  private filterMenuItems(authorizations: string[], menuItems: NbMenuItem[]): NbMenuItem[] {
    return menuItems
      .map((menuItem) => {
        const clonedMenuItem = { ...menuItem };
        if (clonedMenuItem.children?.length) {
          clonedMenuItem.children = this.filterMenuItems(authorizations, clonedMenuItem.children);
          if (clonedMenuItem.children.length === 0) {
            return null;
          }
        } else {
          const accessName = clonedMenuItem.data;
          if (accessName) {
            const hasAuthorization = authorizations.includes(accessName);
            if (!hasAuthorization && clonedMenuItem.data !== ScreenPermissions.VIEW_HOME) {
              return null;
            }
          }
        }
        return clonedMenuItem;
      })
      .filter((item) => item !== null) as NbMenuItem[];
  }

  private createQuickMenuItems(menuItems: NbMenuItem[]): QuickMenu[] {
    const quickMenuItems: QuickMenu[] = [];

    const extract = (menuItems: NbMenuItem[]) => {
      for (const menuItem of menuItems) {
        if (menuItem.link) {
          quickMenuItems.push({
            title: menuItem.title,
            link: menuItem.link
          });
        }

        if (menuItem.children) {
          extract(menuItem.children);
        }
      }
    };
    extract(menuItems);
    return quickMenuItems;
  }

  setMenu(items: NbMenuItem[]): void {
    const newTranslatedItems = this.translateMenu(items);
    if (newTranslatedItems) this.menu$.next(newTranslatedItems);
  }

  setQuickMenu(quickMenu: QuickMenu[]): void {
    const newTranslatedItems = this.translateQuickMenu(quickMenu);
    if (newTranslatedItems) this.quickMenu$.next(newTranslatedItems);
  }

  translateMenu(items: NbMenuItem[]): NbMenuItem[] | null {
    if (!items) return null;
    const translateItems = (items: NbMenuItem[]): NbMenuItem[] => {
      for (const item of items) {
        item.title = this.translate.instant(`LAYOUT.MENU.${item.title}`);
        if (item.children) {
          translateItems(item.children);
        }
      }
      return items;
    };
    translateItems(items);
    return items;
  }

  translateQuickMenu(quickMenu: QuickMenu[]): QuickMenu[] | null {
    if (!quickMenu) return null;
    quickMenu.map((item) => (item.title = this.translate.instant(`LAYOUT.MENU.${item.title}`)));
    return quickMenu;
  }

  createMenu(authorizations: string[]): NbMenuItem[] {
    const menu = this.filterMenuItems(authorizations, this.items);
    return menu;
  }

  createQuickMenu(menu: NbMenuItem[]): QuickMenu[] {
    const quickMenu = this.createQuickMenuItems(menu);
    return quickMenu;
  }

  getMenu(): NbMenuItem[] {
    const menuItems: NbMenuItem[] = this.cookieService.getCookie(DgcCookies.MENU);
    return menuItems;
  }

  getQuickMenu(): QuickMenu[] {
    const quickMenu: QuickMenu[] = this.cookieService.getCookie(DgcCookies.QUICK_MENU);
    return quickMenu;
  }

  updateMenus(): void {
    const menu = this.getMenu();
    const quickMenu = this.getQuickMenu();
    this.setMenu(menu);
    this.setQuickMenu(quickMenu);
  }

  checkUrl(route: string): boolean {
    const menuItems = this.getMenu();
    const isRouteFound = (items: NbMenuItem[]): boolean => {
      for (const item of items) {
        if (item.link === route) {
          return true;
        }

        if (item.children?.length) {
          if (isRouteFound(item.children)) {
            return true;
          }
        }
      }
      return false;
    };
    return isRouteFound(menuItems);
  }

  checkPermission(permission: string): boolean {
    const permissions: string[] = this.cookieService.getCookie(DgcCookies.PERMISSIONS);
    const hasPermission = permissions.includes(permission);
    return hasPermission;
  }

  checkIfUserIsAdmin(): boolean {
    return true; // TODO: ajustar validação pela flag admin master
  }

  getPermissionsByStructure(
    structure_id: string
  ): Observable<ApolloQueryResult<{ permissions_by_structure: ListPermissionsGQLResponse }>> {
    return this.apollo.use('auth').query<{ permissions_by_structure: ListPermissionsGQLResponse }>({
      query: LIST_PERMISSIONS_BY_STRUCTURE,
      variables: {
        structureId: structure_id
      },
      fetchPolicy: 'no-cache'
    });
  }
}
