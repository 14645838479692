import { Inject, Injectable } from '@angular/core';
import { Observable, map, of, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environment';
import { DgcSessionStorage } from '../enums/session-storage.enum';
import { GetWhitelabelOutput, GetWhitelabelResponse, WhitelabelInput } from '@dgc/dtos';
import { ChangeColors } from '../libs/colors';
import { CookieService } from './cookie.service';
import { DgcCookies } from '../enums/cookies.enum';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client';
import {
  LIST_WHITELABEL,
  LIST_WHITELABEL_APP_INITIALIZER,
  LIST_WHITELABEL_CUSTOMIZATION
} from '../graphql/organizations.queries';
import { ImageType } from '../types/image-cropper.type';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  private api = `${environment.api}/organizations`;
  private whitelabel!: GetWhitelabelOutput;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService,
    private apollo: Apollo,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public getStoredWhitelabel(): GetWhitelabelOutput {
    this.whitelabel = JSON.parse(sessionStorage.getItem(DgcSessionStorage.WHITELABEL)!);
    return this.whitelabel;
  }

  getWhitelabel(structureItemId: string): Observable<ApolloQueryResult<{ get_whitelabel: GetWhitelabelResponse }>> {
    return this.apollo.query<{ get_whitelabel: GetWhitelabelResponse }>({
      query: LIST_WHITELABEL,
      variables: { structureItemId },
      fetchPolicy: 'no-cache'
    });
  }

  getWhitelabelForCustomization(
    structureItemId: string
  ): Observable<ApolloQueryResult<{ get_whitelabel: GetWhitelabelResponse }>> {
    return this.apollo.query<{ get_whitelabel: GetWhitelabelResponse }>({
      query: LIST_WHITELABEL_CUSTOMIZATION,
      variables: { structureItemId },
      fetchPolicy: 'no-cache'
    });
  }

  getWhitelabelAppInitializer(): Observable<ApolloQueryResult<{ get_whitelabel_by_domain: GetWhitelabelResponse }>> {
    return this.apollo
      .query<{ get_whitelabel_by_domain: GetWhitelabelResponse }>({
        query: LIST_WHITELABEL_APP_INITIALIZER,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap((res) => {
          if (res.data.get_whitelabel_by_domain.value) this.handleWhitelabel(res.data.get_whitelabel_by_domain.value);
        })
      );
  }

  saveWhitelabel(data: WhitelabelInput): Observable<void> {
    const url = `${this.api}/v1/structure-items/whitelabel`;
    return this.http.post<void>(url, data);
  }

  uploadImages(data: FormData, structure_item_id: string): Observable<Partial<Record<ImageType, string>>> {
    const url = `${this.api}/v1/uploads/${structure_item_id}`;
    return this.http
      .post<{
        data: Partial<Record<ImageType, string>>;
      }>(url, data)
      .pipe(map((res) => res.data));
  }

  public handleWhitelabel(whitelabel: Partial<GetWhitelabelOutput>): void {
    whitelabel = this.addSecondaryColors(whitelabel);
    sessionStorage.setItem(DgcSessionStorage.WHITELABEL, JSON.stringify(whitelabel));
    if (whitelabel.tenant_id)
      this.cookieService.setCookie(DgcCookies.TENANT_ID, whitelabel.tenant_id, environment.expirationDays);
    if (whitelabel.structure_item_id)
      this.cookieService.setCookie(
        DgcCookies.STRUCTURE_ITEM_ID,
        whitelabel.structure_item_id,
        environment.expirationDays
      );
    this.addStyleToBody(whitelabel);
    this.setWhitelabelInBrowser(whitelabel);
  }

  private setWhitelabelInBrowser(whitelabel: Partial<GetWhitelabelOutput>): void {
    this.document.title = whitelabel.name || '';
    const link: HTMLLinkElement =
      this.document.querySelector(`link[rel*='icon']`) || this.document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    if (whitelabel.favicon) link.href = whitelabel.favicon;
    this.document.getElementsByTagName('head')[0].appendChild(link);
  }

  private addStyleToBody(whitelabel: Partial<GetWhitelabelOutput>): void {
    const style = this.getStyle(whitelabel);
    document.body.setAttribute('style', style);
  }

  private addSecondaryColors(whitelabel: Partial<GetWhitelabelOutput>): Partial<GetWhitelabelOutput> {
    if (whitelabel.accent_color) {
      whitelabel.accent_color_bright = ChangeColors.brightenHexColor(whitelabel.accent_color, 30);
      whitelabel.accent_color_dark = ChangeColors.darkenHexColor(whitelabel.accent_color, 30);
      whitelabel.accent_color_alpha = ChangeColors.reduceOpacityHexColor(whitelabel.accent_color, 70);
      whitelabel.accent_color_alpha_plus = ChangeColors.reduceOpacityHexColor(whitelabel.accent_color, 97);
    }
    return whitelabel;
  }

  public getStyle(whitelabel: Partial<GetWhitelabelOutput>): string {
    return `--accent-color: ${whitelabel.accent_color}; --accent-color-dark: ${whitelabel.accent_color_dark}; --accent-color-bright: ${whitelabel.accent_color_bright}; --accent-color-alpha: ${whitelabel.accent_color_alpha}; --accent-color-alpha-plus: ${whitelabel.accent_color_alpha_plus};`;
  }

  updateSystemWhitelabel(whitelabel: Partial<GetWhitelabelOutput>): void {
    this.addSecondaryColors(whitelabel);
    this.addStyleToBody(whitelabel);
    sessionStorage.setItem(DgcSessionStorage.WHITELABEL, JSON.stringify(whitelabel));
  }
}
