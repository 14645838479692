export interface IToastService {
  success: (data: any) => {};
  danger: (data: any) => {};
  warning: (data: any) => {};
  info: (data: any) => {};
}

export interface IToastInputModel {
  title: string;
  content?: string | null;
  duration?: number | null;
  _id?: string;
  icon?: string | null;
  component?: any;
  infinite?: boolean;
  pinned?: boolean;
  context?: any;
}

export enum IToastType {
  SUCCESS = 'success',
  DANGER = 'danger',
  WARNING = 'warning',
  INFO = 'info'
}
