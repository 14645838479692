import { NbThemeService } from '@nebular/theme';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { DgcSessionStorage } from '../enums/session-storage.enum';

@Injectable()
export class TokenStatusInterceptor implements HttpInterceptor {
  private userHasLoggedOff = false;

  constructor(private themeService: NbThemeService, private authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const errorMsg = err?.error?.message;
        if (errorMsg === 'Token expired' || errorMsg === 'No token provided' || errorMsg === 'Unauthorized') {
          if (!this.userHasLoggedOff) {
            this.userHasLoggedOff = true;
            this.logoutUser();
          }
        }
        return throwError(() => err);
      }),
      tap((res: any) => {
        const graphQLError = res?.body?.errors?.[0]?.message;
        if (
          graphQLError === 'Token expired' ||
          graphQLError === 'No token provided' ||
          graphQLError === 'Unauthorized'
        ) {
          if (!this.userHasLoggedOff) {
            this.userHasLoggedOff = true;
            this.logoutUser();
          }
        }
      })
    );
  }

  logoutUser(): void {
    setTimeout(() => {
      sessionStorage.setItem(DgcSessionStorage.EXPIRED_TOKEN, JSON.stringify(true));
      this.themeService.changeTheme('light');
      this.authService.signOut();
      this.userHasLoggedOff = false;
    }, 1000);
  }
}
