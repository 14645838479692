import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const authentication = inject(AuthenticationService);
  const user = authentication.getCurrentUser();
  if (!user) authentication.signOut();
  const isTryingToAccessTermsRoute = state.url === '/authentication/terms';
  const hasDocumentsPending = user.documents_pending;
  if (hasDocumentsPending) {
    if (!isTryingToAccessTermsRoute) {
      router.navigate(['/authentication/terms']);
      return false;
    }
    return true;
  } else {
    if (isTryingToAccessTermsRoute) {
      router.navigate(['/dashboard/home']);
      return false;
    }
    return true;
  }
};

export const DocumentsPendingGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  canActivate(route, state);
