import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private overlayRef!: OverlayRef;
  private spinnerVisible: boolean = false;

  constructor(private overlay: Overlay) {}

  public show(): void {
    if (this.isSpinnerVisible) return;
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop'
    });

    const spinnerPortal = new ComponentPortal(SpinnerComponent);
    this.overlayRef.attach(spinnerPortal);
    this.spinnerVisible = true;
  }

  public hide(): void {
    if (this.overlayRef && this.spinnerVisible) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
      this.spinnerVisible = false;
    }
  }

  public get isSpinnerVisible(): boolean {
    return this.spinnerVisible;
  }
}
