export class ChangeColors {
  static darkenHexColor(hexColor: string, percent: number): string {
    percent = Math.min(100, Math.max(-100, percent));
    hexColor = hexColor.replace(/^#/, '');
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);
    const darkenedR = Math.max(0, r - (r * percent) / 100);
    const darkenedG = Math.max(0, g - (g * percent) / 100);
    const darkenedB = Math.max(0, b - (b * percent) / 100);
    const darkenedHexColor =
      '#' +
      Math.round(darkenedR).toString(16).padStart(2, '0') +
      Math.round(darkenedG).toString(16).padStart(2, '0') +
      Math.round(darkenedB).toString(16).padStart(2, '0');
    return darkenedHexColor;
  }

  static brightenHexColor(hexColor: string, percent: number): string {
    percent = Math.min(100, Math.max(-100, percent));
    hexColor = hexColor.replace(/^#/, '');
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);
    const brightenedR = Math.min(255, r + (255 - r) * (percent / 100));
    const brightenedG = Math.min(255, g + (255 - g) * (percent / 100));
    const brightenedB = Math.min(255, b + (255 - b) * (percent / 100));
    const brightenedHexColor =
      '#' +
      Math.round(brightenedR).toString(16).padStart(2, '0') +
      Math.round(brightenedG).toString(16).padStart(2, '0') +
      Math.round(brightenedB).toString(16).padStart(2, '0');
    return brightenedHexColor;
  }

  static reduceOpacityHexColor(hexColor: string, percent: number): string {
    percent = Math.min(100, Math.max(-100, percent));

    // Remove the '#' symbol if it exists
    hexColor = hexColor.replace(/^#/, '');

    // Parse the hex color into its RGB components
    const r = parseInt(hexColor.slice(0, 2), 16);
    const g = parseInt(hexColor.slice(2, 4), 16);
    const b = parseInt(hexColor.slice(4, 6), 16);

    // Calculate the new alpha value (opacity)
    const newAlpha = Math.min(255, Math.max(0, (255 * (100 - percent)) / 100));

    // Convert the new alpha value to a hexadecimal component
    const alphaHex = Math.round(newAlpha).toString(16).padStart(2, '0');

    // Combine the RGB values with the new alpha value
    const reducedOpacityHexColor = `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
      .toString(16)
      .padStart(2, '0')}${alphaHex}`;

    return reducedOpacityHexColor;
  }
}
