import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  constructor() {}

  setCookie(name: string, value: any, days: number): void {
    const expirationDate = this.getExpirationDate(days);
    const serializedValue = JSON.stringify(value);
    const encryptedValue = window.btoa(serializedValue);
    const encodedValue = encodeURIComponent(encryptedValue);
    const cookieValue = `${name}=${encodedValue};expires=${expirationDate.toUTCString()};path=/`;
    document.cookie = cookieValue;
  }

  getCookie(name: string, shouldParse: boolean = true): any | null {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const trimmedCookie = cookie.trim();
      if (trimmedCookie.startsWith(nameEQ)) {
        const encodedValue = trimmedCookie.substring(nameEQ.length);
        const decodedValue = decodeURIComponent(encodedValue);
        const decryptedValue = window.atob(decodedValue);
        try {
          return shouldParse ? JSON.parse(decryptedValue) : decryptedValue;
        } catch (error) {
          console.error('Error parsing cookie:', error);
          return null;
        }
      }
    }
    return null;
  }

  private getExpirationDate(days: number): Date {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + days);
    return expirationDate;
  }

  private getCookieValue(name: string, serializedValue: string, expirationDate: Date): string {
    return `${name}=${encodeURIComponent(serializedValue)};expires=${expirationDate.toUTCString()};path=/`;
  }

  deleteCookie(name: string): void {
    this.setCookie(name, '', -1);
  }
}
