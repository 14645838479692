export const environment = {
  name: 'qa',
  production: false,
  env_mode: 'qa',
  sw: true,
  GOOGLE_MAPS_KEY: 'AIzaSyDk9wa-ND1DG0gSL-NI5d1y2PN1VeDPM9w',
  decryptUrlKey: 'Ps3cKrGYgy2K',
  WMSAuthKey: '6PCdQZ2C!nC9dLj6',
  activePlayer: 'sldp',
  api: 'https://api-integration.dguardcloud.com.br',
  socketApi: 'https://dgc-sessions.onrender.com',
  clientId: 'm0J8mZyUiigIgwh8UxYRs9zMUJ9mcgPY',
  // NEW_EXPORTER_API: 'https://exporter.dguardcloud.com.br:7441/api/video-exporter',
  // erpIntegrationApi: 'https://dcloud-erp-integration.herokuapp.com',
  serverPublicKey: 'BCRiFRe-eK27DwkIMwik_eSyVL6fHR_KBBDZs7mxR9SYSp_UZxuJ--5rTggmK8fkyiZgZIOtIv_bCvxHAbjbAAc',
  nameCharLimit: 30,
  descriptionCharLimit: 80,
  expirationDays: 1
};
