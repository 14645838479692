import { Route } from '@angular/router';
import { LoggedGuard } from './shared/guards/logged.guard';
import { DocumentsPendingGuard } from './shared/guards/documents-pending.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: 'authentication/login',
    pathMatch: 'full'
  },
  {
    path: '',
    loadComponent: () => import('./layout/full/full.component').then((c) => c.FullComponent),
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard-routes').then((r) => r.DASHBOARD_ROUTES),
        canActivate: [LoggedGuard, DocumentsPendingGuard]
      },
      {
        path: 'config',
        loadChildren: () => import('./modules/config/config-routes').then((r) => r.CONFIG_ROUTES),
        canActivate: [LoggedGuard, DocumentsPendingGuard]
      },
      {
        path: 'resource',
        loadChildren: () => import('./modules/resource/resource-routes').then((r) => r.RESOURCE_ROUTES),
        canActivate: [LoggedGuard, DocumentsPendingGuard]
      }
    ]
  },
  {
    path: '',
    loadComponent: () =>
      import('./layout/authentication/authentication.component').then((c) => c.AuthenticationComponent),
    children: [
      {
        path: 'authentication',
        loadChildren: () =>
          import('../app/modules/authentication/authentication-routes').then((r) => r.AUTHENTICATION_ROUTES)
      },
      {
        path: '**',
        loadComponent: () => import('./layout/not-found/not-found.component').then((c) => c.NotFoundComponent)
      }
    ]
  }
];
