import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { StructureItemParams } from '../models/structure-items-input';
import { environment } from '../../environment';
import { ApolloQueryResult } from '@apollo/client';
import {
  AddStructureItemInput,
  ListStructureItemsForFilterResponse,
  ListStructureItemsResponse,
  StructureItemInput,
  SystemInput
} from '@dgc/dtos';
import {
  LIST_STRUCTURE_ITEMS_TABLE,
  LIST_STRUCTURE_ITEMS_ALL_DATA,
  LIST_STRUCTURE_ITEMS_FILTER,
  LIST_LINKED_STRUCTURE_ITEMS,
  LIST_LINKABLE_STRUCTURE_ITEMS,
  LIST_SINGLE_STRUCTURE_ITEM_FOR_FILTER
} from '../graphql/organizations.queries';
import { Apollo } from 'apollo-angular';
import { StructureItemConfigsResponse } from '../models/structure-item-configs';

@Injectable({
  providedIn: 'root'
})
export class StructureItemService {
  private api = `${environment.api}/organizations`;

  constructor(private http: HttpClient, private apollo: Apollo) {}
  getStructureItems(
    data?: StructureItemParams
  ): Observable<ApolloQueryResult<{ list_structure_items: ListStructureItemsResponse }>> {
    return this.apollo.query<{ list_structure_items: ListStructureItemsResponse }>({
      query: LIST_STRUCTURE_ITEMS_TABLE,
      variables: {
        skip: data?.skip,
        limit: data?.limit,
        sortOrder: data?.sortOrder,
        sortField: data?.sortField,
        affiliatedId: data?.affiliatedId,
        query: data?.query
      },
      fetchPolicy: 'no-cache'
    });
  }

  getSingleStructureItem(
    data?: StructureItemParams
  ): Observable<ApolloQueryResult<{ list_structure_items: ListStructureItemsResponse }>> {
    return this.apollo.query<{ list_structure_items: ListStructureItemsResponse }>({
      query: LIST_STRUCTURE_ITEMS_ALL_DATA,
      variables: {
        structureItemId: data?.structureItemId
      },
      fetchPolicy: 'no-cache'
    });
  }

  checkDomain(structure_item_id: string, domain: string): Observable<boolean> {
    const url = `${this.api}/v1/structure-items/${structure_item_id}/check-domain/${domain}`;
    return this.http.get<boolean>(url);
  }

  getStructureItemsFilters(
    affiliatedId: string | null
  ): Observable<ApolloQueryResult<{ list_structure_items_filter: ListStructureItemsForFilterResponse }>> {
    return this.apollo.query<{ list_structure_items_filter: ListStructureItemsForFilterResponse }>({
      query: LIST_STRUCTURE_ITEMS_FILTER,
      variables: { affiliatedId },
      fetchPolicy: 'no-cache'
    });
  }

  getSingleStructureItemFilters(
    structureItemId: string | null
  ): Observable<ApolloQueryResult<{ list_structure_items_filter: ListStructureItemsForFilterResponse }>> {
    return this.apollo.query<{ list_structure_items_filter: ListStructureItemsForFilterResponse }>({
      query: LIST_SINGLE_STRUCTURE_ITEM_FOR_FILTER,
      variables: { structureItemId },
      fetchPolicy: 'no-cache'
    });
  }

  getLinkedStructureItems(
    data?: StructureItemParams
  ): Observable<ApolloQueryResult<{ list_structure_items: ListStructureItemsResponse }>> {
    return this.apollo.query<{ list_structure_items: ListStructureItemsResponse }>({
      query: LIST_LINKED_STRUCTURE_ITEMS,
      variables: {
        skip: data?.skip,
        limit: data?.limit,
        structureItemIds: data?.structureItemsIds
      },
      fetchPolicy: 'no-cache'
    });
  }

  getLinkableStructureItems(
    data?: StructureItemParams
  ): Observable<ApolloQueryResult<{ list_structure_items: ListStructureItemsResponse }>> {
    return this.apollo.query<{ list_structure_items: ListStructureItemsResponse }>({
      query: LIST_LINKABLE_STRUCTURE_ITEMS,
      variables: {
        skip: data?.skip,
        limit: data?.limit,
        affiliatedId: data?.affiliatedId
      },
      fetchPolicy: 'no-cache'
    });
  }

  getStructureItemConfigs(structureItemId: string): Observable<StructureItemConfigsResponse> {
    const url = `${this.api}/v1/structure-items/${structureItemId}/configs`;
    return this.http.get<StructureItemConfigsResponse>(url);
  }

  createStructureItem(data: AddStructureItemInput): Observable<void> {
    const url = `${this.api}/v1/structure-items`;
    return this.http.post<void>(url, data);
  }

  editStructureItem(id: string, data: StructureItemInput): Observable<void> {
    const url = `${this.api}/v1/structure-items/${id}`;
    return this.http.put<void>(url, data);
  }

  deleteStructureItem(id: string): Observable<void> {
    const url = `${this.api}/v1/structure-items/${id}`;
    return this.http.delete<void>(url);
  }

  saveSystemConfigs(data: SystemInput): Observable<void> {
    const url = `${this.api}/v1/structure-items/system`;
    return this.http.post<void>(url, data);
  }
}
