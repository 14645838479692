import { Injectable } from '@angular/core';
import { AvailableLanguages, Language } from '../types/languages.type';
import { TableColumn } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { DgcSessionStorage } from '../enums/session-storage.enum';
import { TranslationParams } from '../models/translation-params';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private translateService: TranslateService) {}

  setLanguage(language: Language): void {
    localStorage.setItem(DgcSessionStorage.LANGUAGE, language);
  }

  getStoredLanguage(): Language | null {
    return localStorage.getItem(DgcSessionStorage.LANGUAGE) as Language | null;
  }

  getDefaultLanguage(): string {
    const browserLanguageCode = this.getBrowserLanguage();
    const defaultLanguage = this.checkLanguageAvailability(browserLanguageCode) ? browserLanguageCode : 'pt';
    const storedLanguage = this.getStoredLanguage();
    const language = storedLanguage ?? defaultLanguage;
    return language;
  }

  private getBrowserLanguage(): string {
    let language = navigator.language;
    language = language.substring(0, 2);
    return language;
  }

  private checkLanguageAvailability(language: string): boolean {
    return AvailableLanguages.includes(language);
  }

  translateColumns(columns: TableColumn[]): TableColumn[] {
    const translatedColumns = columns.map((col) => ({
      ...col,
      name: this.translateService.instant(col.name as string)
    }));
    return translatedColumns;
  }

  getTranslation(text: string | undefined | null, translationParams?: TranslationParams[]): string {
    if (!text) return '';
    const TranslationParamsDictionary: Record<string, string | number> = {};
    if (translationParams?.length) {
      translationParams.forEach((e) => {
        if (e.arg && e.key) TranslationParamsDictionary[e.key] = e.arg;
      });
    }
    return this.translateService.instant(text, TranslationParamsDictionary);
  }
}
