import { DgcSessionStorage } from './../enums/session-storage.enum';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class CredentialsInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('https://maps.googleapis.com') || request.url.includes('openstreetmap.org')) {
      return next.handle(request);
    }
    request = request.clone({
      withCredentials: true,
      headers: new HttpHeaders({ 'Accept-Language': localStorage.getItem(DgcSessionStorage.LANGUAGE) || 'pt' })
    });
    return next.handle(request);
  }
}
