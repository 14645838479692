import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { FrenchToastModule, ToastPosition, ToastService } from 'ngx-french-toast';
import { NbThemeModule } from '@nebular/theme';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { AppInitializerService, appInitializerFunc } from './shared/services/app-initializer.service';
import { CredentialsInterceptor } from './shared/interceptors/credentials';
import { TokenStatusInterceptor } from './shared/interceptors/token-status';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from './environment';
import { GraphQLModule } from './shared/graphql/graphql.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

registerLocaleData(ptBr);

const config: SocketIoConfig = {
  url: environment.socketApi,
  options: {
    timeout: 1800000
  }
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
    NbThemeModule.forRoot({ name: 'light' }),
    FrenchToastModule.forRoot({
      defaultDuration: 7000,
      colors: {
        danger: '#e10044',
        success: '#28A961',
        info: '#2915b8',
        warning: '#a869e3',
        timebar: 'linear-gradient(45deg, #ffdc00, #fe9826)',
        autoGradient: false
      },
      position: ToastPosition.BOTTOM_RIGHT,
      font: {
        contentFontSize: '14px',
        titleFontSize: '15px',
        family: 'Inter'
      }
    }),
    FormsModule,
    ReactiveFormsModule,
    SocketIoModule.forRoot(config),
    GraphQLModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: 'ToastService', useExisting: ToastService },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFunc,
      multi: true,
      deps: [AppInitializerService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: CredentialsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenStatusInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
