import { AfterContentInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { NbMenuItem } from '@nebular/theme';
import { CookieService } from './shared/services/cookie.service';
import { AuthenticationService } from './shared/services/authentication.service';
import { SocketService } from './shared/services/socket.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { SpinnerService } from './shared/services/spinner.service';
import { GetWhitelabelOutput, StructureItemConfigsDTO } from '@dgc/dtos';
import { DgcCookies } from './shared/enums/cookies.enum';
import { DgcSessionStorage } from './shared/enums/session-storage.enum';
import { MessagingService } from './shared/services/messaging.service';
import { IToastType } from './shared/classes/itoast-service.class';

@Component({
  selector: 'dgc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  whitelabel!: GetWhitelabelOutput;
  items: NbMenuItem[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private el: ElementRef,
    private authService: AuthenticationService,
    private socketService: SocketService,
    private router: Router,
    private spinner: SpinnerService,
    private messagingService: MessagingService
  ) {}

  ngOnInit(): void {
    // this.connectSocket(); // TODO: lembrar de descomentar
    this.listenForNavigation();
  }

  checkForAppInitializerError(): void {
    const hasError = sessionStorage.getItem(DgcSessionStorage.APP_INITIALIZER_ERROR);
    if (hasError) {
      const err = JSON.parse(hasError);
      sessionStorage.removeItem(DgcSessionStorage.APP_INITIALIZER_ERROR);
      this.messagingService.translateToast({
        toastInput: {
          title: 'COMMON.UNABLE_TO_INITIALIZE_APP_TITLE',
          content: err?.error?.message || err?.error?.error || 'COMMON.UNABLE_TO_INITIALIZE_APP'
        },
        type: IToastType.DANGER
      });
    }
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.checkForAppInitializerError(); // TODO: melhorar isso
      this.setGlobalCardHeightVariable(); // TODO: melhorar isso
    }, 0);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  listenForNavigation(): void {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e) => this.navigationInterceptor(e));
  }

  navigationInterceptor(event: any): void {
    if (event instanceof NavigationStart) {
      this.spinner.show();
    }
    const shouldHideSpinner =
      event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError;
    if (shouldHideSpinner) {
      this.spinner.hide();
    }
  }

  setGlobalCardHeightVariable(): void {
    try {
      const element = this.el.nativeElement;
      const styles = window.getComputedStyle(element);
      const layoutPadding = styles.getPropertyValue('--layout-padding-top').trim();
      const headerHeight = styles.getPropertyValue('--header-height').trim();
      const height = `calc(100vh - (${layoutPadding} * 2 + ${headerHeight} + 4rem)`;
      this.document.body.style.setProperty('--custom-card-height', height);
    } catch {
      console.error('Não foi possível definir a altura padrão do card.');
    }
  }

  connectSocket(): void {
    if (this.authService.isLogged()) {
      const user = this.authService.getCurrentUser();
      const structureItemConfig: StructureItemConfigsDTO = this.cookieService.getCookie(DgcCookies.STRUCTURE_ITEM);
      this.socketService.connect(user, structureItemConfig);
    }
  }
}
